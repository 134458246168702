import { cn } from "@rocket-resume/utils";
import { forwardRef, useState } from "react";
import { Eye, EyeSlash } from "@phosphor-icons/react";

export type InputProps = {
  hasError?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, hasError = false, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const toggleVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return(
      <div className="relative">
        <input
        ref={ref}
        type={isPasswordVisible ? 'text': type}
        autoComplete="off"
        className={cn(
          "flex h-10 w-full rounded border border-border bg-transparent px-3 py-0.5 !text-sm ring-0 ring-offset-transparent transition-colors [appearance:textfield] placeholder:opacity-80 hover:bg-secondary/20 focus:border-primary focus:bg-secondary/20 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
          "file:border-0 file:bg-transparent file:pt-1 file:text-sm file:font-medium file:text-primary",
          hasError ? "border-error" : "border-[#3F3D3D]",
          className,
        )}
        {...props}
      />
      {type === "password" && (
        <div className="absolute right-2 top-2 text-gray-500 cursor-pointer">
          {isPasswordVisible ? <Eye size={20} onClick={toggleVisibility}/> : <EyeSlash size={20} onClick={toggleVisibility}/>}
          {/* <EyeSlash size={16} /> */}
        </div>
        )}
    </div>
    )
  }
);

Input.displayName = "Input";
